import React, { useContext, useState, useEffect } from "react";
import ReactSwitch from "react-switch";
import { ThemeContext } from "../../../App";
import { FaMoon, FaSun } from "react-icons/fa6";
import "./Navbar.css";
import logo from "../../../Images/jv.png";
import logo2 from "../../../Images/jv2.png";
import { HiMenu } from "react-icons/hi";
import { Link } from "react-scroll";

function Navbar() {
  const { toggleTheme, theme } = useContext(ThemeContext);

  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth <= 841) {
        setShowMenu(false);
      } else {
        setShowMenu(true);
      }
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="centered">
      <div className="navbar">
        <div className="content">
          {theme === "dark" ? (
            <img src={logo} alt="Logo" draggable="false" />
          ) : (
            <img src={logo2} alt="Logo" draggable="false" />
          )}

          <ul className="list" style={{ display: showMenu ? "flex" : "none" }}>
            <li className="item">
              <Link
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
              >
                <span onClick={toggleMenu}>Home</span>
              </Link>
            </li>
            <li className="item">
              <Link
                activeClass="active"
                to="languages"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
              >
                <span onClick={toggleMenu}>Languages</span>
              </Link>
            </li>
            <li className="item">
              <Link
                activeClass="active"
                to="portfolio"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
              >
                <span onClick={toggleMenu}>Portfolio</span>
              </Link>
            </li>
            <li className="item">
              <Link
                activeClass="active"
                to="aboutme"
                spy={true}
                smooth={true}
                offset={-80}
                duration={500}
              >
                <span onClick={toggleMenu}>About Me</span>
              </Link>
            </li>
          </ul>
          <div className="tema">
            <ReactSwitch
              onChange={toggleTheme}
              checked={theme === "dark"}
              checkedIcon={
                <div className="custom-on-icon">
                  <FaSun />
                </div>
              }
              uncheckedIcon={
                <div className="custom-off-icon">
                  <FaMoon />
                </div>
              }
            />
          </div>
          <button className="menu_button" onClick={toggleMenu}>
            <HiMenu />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
