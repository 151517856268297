import "./Footer.css";
import synt_white from "../../../Images/synt_white.png";
import synt_black from "../../../Images/synt_black.png";
import logo_white from "../../../Images/jv.png";
import logo_color from "../../../Images/jv2.png";
import React, { useContext } from "react";
import { ThemeContext } from "../../../App";
import { FaDiscord } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

function Footer() {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="footer">
      <div className="left">
        <div className="featured">
          <span>Featured</span>
          <a href="https://synt.store" target="_blank" rel="noreferrer">{theme === "dark" ? <img src={synt_white} alt="SYNT" draggable="false" /> : <img src={synt_black} alt="SYNT" draggable="false" />}</a>
        </div>
        <div className="contact">
            <h2>Contact</h2>
            <span><FaDiscord title="Discord" /> Aromiic</span>
            <span><MdEmail title="Email" /> joaoveiga.webdev@gmail.com</span>
        </div>
      </div>
      <div className="right">{theme === "dark" ? <img src={logo_white} alt="SYNT" draggable="false" /> : <img src={logo_color} alt="SYNT" draggable="false" />}</div>
    </div>
  );
}

export default Footer;
