import React from "react";
import "./Bars.css";

const Bars = ({ percentage, name, color }) => {
  return (
    <div className="skill">
      <div className="progressBar">
        <div style={{ backgroundColor: color, width: `${percentage}%` }}>
          <span>{percentage}%</span>
        </div>
      </div>
      <div className="name">{name}</div>
    </div>
  );
};

export default Bars;
