import "./App.css";
import { createContext, useState } from "react";
import Navbar from "./Components/layout/Navbar/Navbar";
import Banner from "./Components/page/Banner/Banner";
import Languages from "./Components/page/Languages/Languages";
import Portfolio from "./Components/page/Portfolio/Portfolio";
import AboutMe from "./Components/page/AboutMe/AboutMe";
import Footer from "./Components/layout/Footer/Footer";

export const ThemeContext = createContext(null);

function App() {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      <div className="App" id={theme}>
        <Navbar />
        <Banner />
        <Languages />
        <Portfolio />
        <AboutMe />
        <Footer />
      </div>
    </ThemeContext.Provider>
  );
}

export default App;
