import "./Portfolio.css";

function Portfolio() {
  return (
    <section id="portfolio">
      <div className="portfolio">
        <div className="title">Portfolio</div>
        <div className="sites">
          <div>Developing</div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
