import "./Banner.css";
import React, { useContext } from "react";
import { ThemeContext } from "../../../App";
import logo from "../../../Images/jv.png";
import logo2 from "../../../Images/jv2.png";

function Banner() {
  const { theme } = useContext(ThemeContext);

  return (
    <section id="home">
      <div className="banner">
        <div className="title">
          <span className="web">Web</span>
          <span className="dev">Developer</span>
        </div>
        <div className="logo">
          {theme === "dark" ? (
            <img src={logo} alt="Logo" draggable="false" />
          ) : (
            <img src={logo2} alt="Logo" draggable="false" />
          )}
        </div>
      </div>
    </section>
  );
}

export default Banner;
