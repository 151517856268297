import Bars from "./Bars/Bars";
import "./Languages.css";

function Languages() {
  return (
    <section id="languages">
      <div className="language">
        <div className="title">Development Languages</div>

        <div className="skills">
          <Bars percentage={85} name="HTML" color="orangered" />
          <Bars percentage={60} name="CSS" color="darkcyan" />
          <Bars percentage={30} name="REACT.JS" color="darkslategray" />
          <Bars percentage={70} name="PHP" color="purple" />
          <Bars percentage={10} name="C" color="blue" />
          <Bars percentage={70} name="JAVASCRIPT" color="yellow" />
        </div>
      </div>
    </section>
  );
}

export default Languages;
