import "./AboutMe.css";

function AboutMe() {
  return (
    <section id="aboutme">
      <div className="aboutMe">
        <div className="title">About Me</div>
        <p>
          Hey there, I'm João Veiga, 19 years old, and I've been into web
          development for about three years now. It's been a bit of a journey—I
          actually started off back in 2017 with Java. Back then, I was really
          into Minecraft, so I picked up Java to create plugins for the game.
          However, as my interests shifted away from Minecraft, I found myself
          exploring other programming languages to expand my skill set and dive
          into web development. I began with HTML and CSS, which were relatively
          easy to grasp. But soon enough, I hit a point where I couldn't build
          more complex systems. That's when I discovered PHP, which allowed me
          to create more dynamic and functional websites. Although I haven't
          been super active recently, I've returned to the scene with a renewed
          enthusiasm. I've been learning React.js and diving into C, which has
          been pretty exciting. If you ever need anything, feel free to reach
          out to me via email or Discord. You'll find the details in the footer.
          Cheers!
        </p>
      </div>
    </section>
  );
}

export default AboutMe;
